@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&display=swap');
@import './theme.scss';


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.backdrop {
    width: 100%;
    height: 100vh;
    background-color: rgba(85, 85, 85, 0.2);
    opacity: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 9999;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

body {
    background-color: #f0f0f0;
    color: #2b2b2b;
   
}

a {
    text-decoration: none;
    color: unset;
}

.testing {
    background-color: red;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999999999;
}

.pointer {
    cursor: pointer;
}

.medium-text {
    font-size: 17px;
}

.black-text {
    color : var(--black)
}

.white-text {
    color : var(--white)!important;
}

.big-text {
    font-size: 20px!important;
    font-weight: 500;
}

.text-small-length-overflow {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.img-icon-size {
    width: 26px;
}

.icon-size-small {
    width: 14px;
}

.danger-bg {
    background-color: var(--red)!important;
}

.warning-bg {
    background-color: var(--orange)!important;
}

.white-bg {
    background-color: var(--white)!important;
}

.blue-bg {
    background-color: var(--blue)!important;
}

.blue-active-bg {
    background-color: var(--active-blue)!important;
}

.primary-button {
    font-family: 'Montserrat', sans-serif!important;
    //font-size: 20px!important;
    border-radius: var(--border-radius)!important;
    font-size: bold!important;
    box-shadow: var(--shadow)!important;
    font-weight: bold!important;
    max-height: 50px!important;
    min-height: 50px!important;
    width: auto;
}

.square-primary-button {
    font-family: 'Montserrat', sans-serif!important;
    //font-size: 20px!important;
    border-radius: var(--border-radius)!important;
    font-size: bold!important;
    box-shadow: var(--shadow)!important;
    font-weight: bold!important;
    max-height: 50px!important;
    min-height: 50px!important;
    max-width: 60px!important;
    min-width: 60px!important;
    width: auto;
}

.fab-primary-button {
    max-height: 60px!important;
    min-height: 60px!important;
    max-width: 60px!important;
    min-width: 60px!important;
    box-shadow: var(--shadow)!important;
}

.mr-5 {
    margin-right: 2%;
}
.ml-5 {
    margin-left: 2%!important;
}
.p-5 {
    padding: 3%!important;
}

.mr-base {
    margin-right: 25px!important;
}

.max-width {
    width: 100%!important;
}

.modal-folder {
    width: auto;
    z-index: 99900;
    border-radius: var(--border-radius);
    height: auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.input-folder-container {
    padding: 27px;
    padding-bottom: 0;
    width: 100%;
}

.input-folder {
    min-width: 300px;
    min-height: 40px;
    font-size: 20px;
    padding: 3%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    outline: none;
    border-bottom-color: var(--active-blue);
}

.add-folder-container {
    padding: 27px;
    width: 100%;
}