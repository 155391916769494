@import "../../theme.scss";

.page {
  padding: 2%;
  width: calc(100% - 320px);
  display: flex;
  background-color: var(--grey);
  flex-direction: column;
  min-height: 100vh;
}

.breadcrumb-container {
  display: flex;
  width: 100%;
  margin-bottom: 2%;
  overflow-x: auto;
  padding-left: 1%;
  padding-right: 1%;
}
.navbar {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 1%;
  padding-right: 1%;
}

.table-header {
  border-bottom: 2px solid var(--active-blue);
}
.table-header-text {
  color: var(--active-blue);
}

.row-table-container {
  width: 100%;
  overflow-x: auto;
  padding-left: 1%;
  padding-right: 1%;
  .row-table {
    min-width: 800px;
  }
}
.white-table-row {
  background-color: var(--white) !important;
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
}

.table-row {
  height: 65px;
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  .name-header {
    width: 55%;
    display: flex;
    align-items: center;
    .text-header {
      width: 100%;
    }
    .check-list-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%;
      margin-left: 10px;
      margin-right: 10px;
      .checkbox {
        width: 70%;
      }
      .preview-list {
        width: 100%;
      }
    }

    .img-list-container {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .checkbox-list {
        min-width: 50px;
      }

      .preview-list {
        width: 50px;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        .preview-container-list {
          height: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: inherit;
        }
      }
    }
  }
  .size-header {
    width: 17%;
    display: flex;
    align-items: center;
  }
  .modify-header {
    width: 17%;
    display: flex;
    align-items: center;
  }
  .download-header {
    width: 10%;
    min-width: 130px;
    display: flex;
    align-items: center;
    padding: 3px;
    .read-only {
      display: none;
    }
  }
}

.button-delete {
  width: 100%;
  height: 100%;
  background: var(--red);
  color: var(--white);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  min-width: 50px;
  cursor: pointer;
}
.button-download {
  min-width: 50px;
  width: 100%;
  height: 100%;
  background: var(--active-blue);
  color: var(--white);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.collection-action-container {
  padding-left: 1%;
  padding-right: 1%;
  margin-bottom: 2%;
  min-height: 50px;
  max-height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .searchbar-container {
    width: 70%;
    height: 100%;
    display: flex;
    border-bottom: 2px solid var(--blue);
    min-height: 50px;
    .input-serchbar {
      background-color: transparent;
      width: 92%;
      border: 0;
      color: var(--blue);
      outline: none;
    }
  }
  .actions-container {
    // width: 10%;
    // min-width: 50px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    .action {
      cursor: pointer;
      width: 60px;
      height: 100%;
      border-radius: var(--border-radius);
      background-color: var(--active-blue);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      font-size: 28px;
    }
  }
}

.color-white {
  color: white;
}

.rename-folder {
  width: 100%;
  height: 100%;
  background-color: rgba(85, 85, 85, 0.2);
  position: fixed ;
  z-index: 99000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-left{
  margin-left: 10px;
}  

.row {
  display: flex;
  align-items: center;
  width: auto;
  height: 10vh;

  .row-block {
    color: var(--white);
    border-radius: var(--border-radius);
    background-color: var(--active-blue);
    box-shadow: var(--shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .row-block-disabled {
    color: #fff;
    border-radius: var(--border-radius);
    background-color: var(--blue);
    box-shadow: var(--shadow);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  p {
    margin: 20px;
  }
}

.card-cover {
  padding: 1%;
  width: calc(100% / 4);
  position: relative;
  float: left;
}

.card {
  color: black;
  background-color: var(--white);
  height: 100%;
  width: 100%;
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
  .selector {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    min-height: 55px;
    .checkbox {
      width: 36px;
      cursor: pointer;
    }
  }
  .preview {
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .jpg:hover {
      cursor: pointer;
    }
  }
  .title {
    width: 100%;
    text-align: center;
    height: 4vh;
    word-break: break-all;
    margin-bottom: 5px;
    .text {
      margin-left: 2%;
      margin-right: 2%;
    }
  }
  .action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .read-only {
    display: none;
  }
}

.loader-container {
  width: 400px;
  height: 150px;
  position: absolute;
  z-index: 999;
  padding: 2%;
  right: 0;
  bottom: 0;
  .loader {
    border-radius: var(--border-radius);
    width: 100%;
    height: 100%;
    background: var(--white);
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    .text {
      height: 50%;
      color: var(--blue);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bar-container {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .cover-bar {
        width: 80%;
        height: 40%;
        background-color: var(--grey);
        border-radius: var(--border-radius);
        .load-bar {
          height: 100%;
          border-radius: var(--border-radius);
          background-color: var(--blue);
          //background-image: linear-gradient( var(--blue), var(--white)), linear-gradient(to right,  var(--blue), var(--white))
          //animation: spin 2s linear infinite;
          //border-image: linear-gradient(45deg, rgb(0,143,104), rgb(250,224,66)) 1;
        }
      }
    }
  }
}

.add-container {
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 89;
  padding: 2%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--active-blue);
  border-bottom-left-radius: 100%;
  font-size: 45px;
  color: var(--white);
  box-shadow: var(--shadow);
  .loader {
    position: fixed;
    width: 80px;
    height: 80px;
    top: 0;
    bottom: 0;
    font-size: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.button-area {
  height: 10vh;
  min-width: 200px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: 0;
  right: 0%;
  margin-right: 3.325rem;
  .download-all-button {
    width: 200px;
    padding: 2%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 20px;
    font-weight: 800;
    color: var(--white);
    background-color: var(--active-blue);
    border-radius: var(--border-radius);
  }
  .read-only {
    display: none;
  }
}

.buttons {
  display: flex;
}

.select-all-button {
  margin-right: 3%;
}

.delete-all-button {
  margin-right: 3%;
  background-color: var(--red);
  width: 200px;
  padding: 2%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
  font-weight: 800;
  color: var(--white);
  border-radius: var(--border-radius);
}

.add-file-button {
  background-color: var(--active-blue);
  min-width: 64px;
  min-height: 64px;
  padding: 15%;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}

$breakpoint-phone: 420px;
$breakpoint-tablet: 1100px;
$breakpoint-samll-screen: 1500px;

@media (max-width: $breakpoint-samll-screen) {
  .card-cover {
    width: calc(100% / 3);
    .preview {
      height: 20vh;
    }
  }
  .page {
    width: 100%;
  }
}

@media (max-width: $breakpoint-tablet) {
  .card-cover {
    width: calc(100% / 3);
    .preview {
      height: 20vh;
    }
  }
  .page {
    margin-top: 10vh;
    width: 100%;
  }
}

@media (max-width: $breakpoint-phone) {
  .card-cover {
    width: calc(100% / 1);
    .preview {
      height: 20vh;
    }
  }
  .page {
    margin-top: 10vh;
    width: 100%;
  }
}
@media (max-width: $breakpoint-tablet) {
  .navbar {
    display: none;
  }
}
