@import '../../../theme.scss';







.modal {
    background-color: white;
    min-width: 277px;
    border-radius: 5px;
    max-width: 500px;
    box-shadow: var(--shadow);
    .modal-title-container {
        text-align: left;
        padding-top: 27px;
        padding-left: 27px;
        padding-bottom: 27px;
        padding-right: 27px;

        border-bottom: 1px solid var(--light-grey)
    }
    .modal-body-container {
        padding-top: 27px;
        padding-left: 27px;
        padding-bottom: 27px;
        padding-right: 27px;
    }
    .modal-action-container {
        display: flex;
        padding-left: 27px;
        padding-bottom: 27px;
        padding-right: 27px;
        .undo-button {
            margin-right: 27px;
        }
    }
}