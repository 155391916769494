:root {
    --border-radius: 5px;
    --blue : #003D4C;
    --active-blue : #5B919F;
    --white: #FFFFFF;
    --light-grey: #D0D0D0;
    --grey: #F8F8F8;
    --dark-grey: #707070;
    --red: #AA332F;
    --orange: orange;
    --black: #3C3C3B; 
    --shadow: 0px 3px 6px #00000029;
}