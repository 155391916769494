@import "../../theme.scss";

.user-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  //min-width: 200px;

  .email-text {
    margin-right: 1rem;
  }

  .button {
    .log-out-button {
      width: 60px;
      height: 60px;
      background-color: var(--red);
      color: var(--white);
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: transform 0.5s ease-in;
      transition-delay: 3s;

      .icon {
        color: var(--grey);
        font-size: 1.5rem;
      }
    }
    :hover {
      background-color: rgb(128, 9, 9);
    }
  }

  .button-login {
    .log-out-button {
      width: 60px;
      height: 60px;
      background-color: var(--blue);
      color: var(--white);
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: transform 0.5s ease-in;
      transition-delay: 3s;

      .icon {
        color: var(--grey);
        font-size: 1.5rem;
      }
    }
    :hover {
      background-color: var(--blue);
    }
  }

  .login {
    background-color: var(--blue);
  }
}
