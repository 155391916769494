@import "../../theme.scss";

.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: 320px;
  background-image: url("../../assets/sidebar/sidebar-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &__logo {
    display: grid;
    place-items: center;
    height: 140px;
    font-size: 1.3rem;
    font-weight: 700;
    padding: 20px;
    font-family: "Mochiy Pop P one", sans-serif;
    margin-left: 12%;
  }

  &__menu {
    position: relative;
    padding: 3%;
    &__item {
      display: flex;
      align-items: center;
      place-content: flex-start;
      padding: 1rem 3rem;
      font-size: 1.05rem;
      font-weight: 500;
      color: white;
      transition: color 0.3s ease-in-out;

      &.active {
        color: var(--blue);
        border-radius: var(--border-radius);
        background: #ffffffd8 0% 0% no-repeat padding-box;
      }
      .icon {
        width: 20px;
        height: 20px;
      }

      &__icon {
        margin-right: 1rem;
        display: flex;
        i {
          font-size: 1.75rem;
        }
      }
    }

    &__indicator {
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - 40px);
      border-radius: var(--border-radius);
      background-color: #fff;
      z-index: -1;
      transform: translateX(-50%);
      transition: 0.3s ease-in-out;
    }
  }
}

.hamburger {
  display: none;
  color: white;
}

.topbar {
  display: none;
  .navbar {
    height: 10vh;
    width: 100%;
    background-image: url("../../assets/sidebar/sidebar-bg.jpg");
    background-position: -574px 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 99999;
    .hamburger {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 20px;
      color: white;
      font-size: 30px;
    }
    .logo {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 75pt;
      }
    }
    .user {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 20px;
      justify-content: flex-end;
      color: white;
      font-size: 30px;
    }
  }

  .menu-topbar {
    z-index: 99999;
    margin-top: 10vh;
    background-image: url("../../assets/sidebar/sidebar-bg.jpg");
    background-position: -574px -10vh;
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 3%;
    width: 100%;
    position: fixed;
  }
}

$breakpoint-phone: 420px;
$breakpoint-tablet: 1100px;
@media (max-width: $breakpoint-tablet) {
  .sidebar__logo {
    display: none;
  }
  .sidebar {
    display: none;
  }
  .hamburger {
    display: block;
    width: 100%;
    height: 5%;
    color: white;
    font-size: 40px;
    height: 10vh;
    cursor: pointer;
  }

  .topbar {
    display: block;
    // background-image: url('../../assets/sidebar/sidebar-bg.jpg');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    height: auto;
    width: 100%;
  }
}
