@import '../../theme.scss';

.modal-special {
    width: 80%;
    z-index: 99999;
    border-radius: var(--border-radius);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 45%;
    background-color: white;
    padding: 27px;
    padding-top: 27px;
    padding-bottom: 0;
}

.modal-folder {
    width: auto;
    z-index: 99999;
    border-radius: var(--border-radius);
    height: auto;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.file-carousel {
    width: 80%;
    overflow-y: hidden;
    background-color: white;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    padding: 27px;
}

.folder-name {
    width: 80%;
    padding: 27px;
    padding-bottom: 0;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.folder-bottom {
    width: 80%;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.drop {
    height: 100%;
    border: 2px var(--active-blue);
    border-style: dashed;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 26px;
}

.mini-file {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    min-height: 110px;
    max-height: 110px;
    height: 110px;
    margin-right: 80px;
}


.add-folder-container {
    padding: 27px;
    width: 100%;
}

.delete-icon {
    width: 100%;
    height: 20%;
    cursor: pointer;
    justify-content: end;
    font-size: 130%;
    color: red;
}

.mini-file-preview {
    width: 100%!important;
    height: 60%!important;
}

.mini-file-name {
    width: 100%;
    height: 20%;
    color: black;
    font-size: 12px;
    text-align: center;
    
}

.input-folder-container {
    padding: 27px;
    padding-bottom: 0;
    width: 100%;
}

.input-folder {
    min-width: 300px;
    min-height: 40px;
    font-size: 20px;
    padding: 3%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    outline: none;
    border-bottom-color: var(--active-blue);
}

.icon-size-small {
    font-size: 30px;
}