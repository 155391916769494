.image-modal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
  width: auto;
  overflow: auto;
  img {
    max-width: 100%;
    height: calc(100vh - 8rem);
    width: calc(100vw - 8rem);
    object-fit: cover;
  }
}

$breakpoint-phone: 420px;

@media (max-width: $breakpoint-phone) {
  .image-modal-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
    width: auto;
    overflow: auto;
    img {
      max-width: 100%;
      height: calc(100vh - 30rem);
      width: 100vw;
      object-fit: cover;
    }
  }
}
