.layout {
    display: flex;
}

$breakpoint-tablet: 1100px;
@media (max-width: $breakpoint-tablet) {
    .layout {
        display: flex;
        flex-direction: column;
    }
}