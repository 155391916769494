.img-preview {
    max-width: 150px;
    max-height: 100%;
}

.img-preview-standard {
    width: 100%;
    height: 100%;
}

.video-preview {
    max-width: 160px;
    max-height: 163%;
    border: 0;
    border-radius: 5px;
}


$breakpoint-phone: 420px;
$breakpoint-tablet: 1100px;
$breakpoint-small-screen: 1500px;

@media (max-width: $breakpoint-small-screen) {
    .img-preview {
        max-width: 100px;
        max-height: 100%;
    }
}

@media (max-width: $breakpoint-tablet) {
    .img-preview {
        max-width: 90px;
        max-height: 100%;
    }
}

@media (max-width: $breakpoint-phone) {
    .img-preview {
        max-width: 90px;
        max-height: 100%;
    }
}