.files {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 20000;
    width: auto;
    height: auto;
}

.file-container {
    //width: 30vw;
    min-width: 30vw;
    max-width: 70vw;
    height: 160px;
    padding: 25px;
    right: 0;
    bottom: 0;
    .loader {
        padding: 8px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: var(--border-radius);
        width: 100%;
        height: 100%;
        background: var(--white);
        box-shadow: var(--shadow);
        display: flex;
        flex-direction: column;   
        .text {
            height: 50%;
            color: #3e5565;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .bar-container {
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .cover-bar {
                width: 80%;
                height: 40%;
                background-color: var(--dark-grey);
                border-radius: var(--border-radius);
                .load-bar {
                    height: 100%;
                    border-radius: var(--border-radius);
                    background-color: var(--active-blue);
                    //background-image: linear-gradient( #3e5565, white), linear-gradient(to right,  #3e5565, white)
                    //animation: spin 2s linear infinite;
                    //border-image: linear-gradient(45deg, rgb(0,143,104), rgb(250,224,66)) 1;
                }
            }
        }
    }
}